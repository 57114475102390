import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/Docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Founder's guide to 360 reviews`}</h2>
    <p>{`As a founder, you often lack clarity on how well you're performing.`}</p>
    <p>{`Metrics tell part of the story - growth, profitability, employee retention, etc. Feedback from your board/advisors/peers tell another part. But these often provide an incomplete picture on how you're performing as a leader and the health of your team.`}</p>
    <p>{`360 reviews are a crude solution to this complex problem. They arm you with subjective feedback from a large subset of the organization, identifying blind spots and areas of improvement. Data from these reviews can help you become a better manager/exec as the company grows.`}</p>
    <p>{`These reviews also inspire a culture of bi-directional feedback. It's often difficult to give upwards feedback - given power dynamics at play - which prevents a company performing at its best. A structured process for upwards feedback can help.`}</p>
    <h2>{`Take 360s with a grain of salt`}</h2>
    <p>{`Feedback from 360 reviews can feel heavy. This feedback inherently feels more personal and emotional than feedback on tasks or projects. `}</p>
    <p>{`Given this, you might feel pressured to address all improvement areas immediately. Or feel imperative need to target and mend relationships.`}</p>
    <p>{`But remember that 360 feedback is `}<strong parentName="p">{`one of many variables`}</strong>{` in defining your success as a leader. `}</p>
    <p>{`First and foremost, your success as a leader depends on your ability to grow the business and achieve profitability. At my startup, the execs with the most favorable 360 reviews tended to underperform on their team OKRs (the execs with the least favorable `}<em parentName="p">{`also`}</em>{` tended to underperform). The challenge of being an exec is in balancing empathy/support for your team while `}<strong parentName="p">{`also`}</strong>{` pushing them to achieve ambitious goals.`}</p>
    <p>{`Recognize that 360 feedback is provided by individuals who have a narrow window into you and your work, and often done so in haste or in the middle of a tough day.  Given this, 360 feedback is best used as a snapshot (to be compared against future snapshots) of how well you're managing and motivating the team. `}</p>
    <p>{`360 feedback should be used to identify the 2-3 most important areas of improvement to focus on over the next 12 months. `}<strong parentName="p">{`It's a directional guide, not an absolute statement of you and your worth.`}</strong></p>
    <h2>{`When to run a 360`}</h2>
    <p>{`360 reviews become most useful when the org size grows beyond ~15 people. `}</p>
    <p>{`At that stage, you no longer have bandwidth to be meeting everyone on a regular basis - so your team's ability to be forthcoming with feedback diminishes. It takes a feeling of trust/closeness to be willing to give feedback, which is rare if you're only meeting someone once a month.`}</p>
    <p>{`Prior to then, you should look to foster a stronger culture of direct feedback. And even with 360 reviews, people working closely together should be encouraged to give regular direct feedback. `}<strong parentName="p">{`360s are not a replacement for a culture of direct feedback.`}</strong></p>
    <p>{`The team should also have capacity to be thoughtful around the 360 process. Even if the questionaire is short, it can be hard to context switch into a feedback state. And if you're running 360s for the whole org, it can take up a day of time for each team member.`}</p>
    <p>{`That said, a shorter form 360 process (ie. 2-3 questions) done regularly is far better than not doing one for lack of time. Feedback is an `}<strong parentName="p">{`investment`}</strong>{` in the team and individuals' long term performance, and as with any investment it carries a short term cost.`}</p>
    <h2>{`How to run a 360`}</h2>
    <p>{`There aren't great off the shelf tools to run 360s for companies <50 people. At larger sizes, tools like Lattice or Culture Amp include features for 360 reviews. At smaller sizes, it can be simpler to use Survey Monkey or similar to administer. Your Ops or HR person will be best to administer it as it'll take time to set up right.`}</p>
    <p>{`You'll want the feedback to be anonymous, and from at least 5 people (to preserve anonyminity). You'll also want the feedback to be sourced from both people who work with each team member closely plus people who interact with them occasionally. At my startup, we had our leadership team share their personal results (eg. growth areas) with the entire team, to help promote a culture of self-improvement.`}</p>
    <p>{`Depending on your goals, you can do a 360 review just for founders, for all leadership roles, or for everyone at the company.`}</p>
    <p>{`The quick method (detailed below) is best suited for 360 reviews of individual contributors (or for use at small companies), while the complete method is best suited for in-depth feedback about founders / managers. However, it's better to do lighterweight feedback more regularly than delaying something heavier.`}</p>
    <p>{`These are intended as suggestions (rather than science), so feel to mix and match or come up with your own questions.`}</p>
    <h4>{`The quick method`}</h4>
    <p><em parentName="p">{`(for individual contributors or smaller companies)`}</em></p>
    <p><strong parentName="p">{`Multiple choice questions:`}</strong>{`
`}<em parentName="p">{`(scale of 1-5)`}</em></p>
    <ol>
      <li parentName="ol">{`They are effective at accomplishing their day to day tasks`}</li>
      <li parentName="ol">{`They think strategically about longer term initiatives`}</li>
      <li parentName="ol">{`They are easy to work with and help people around them succeed`}</li>
    </ol>
    <p><strong parentName="p">{`Written response:`}</strong></p>
    <ol>
      <li parentName="ol">{`What are they doing well?`}</li>
      <li parentName="ol">{`What can they improve on?`}</li>
      <li parentName="ol">{`Any additional thoughts/feedback?`}</li>
    </ol>
    <h4>{`The complete method`}</h4>
    <p><em parentName="p">{`(for managers / founders at 15ppl+ companies)`}</em></p>
    <p><strong parentName="p">{`Multiple choice questions:`}</strong>{`
`}<em parentName="p">{`(scale of 1-5)`}</em></p>
    <ol>
      <li parentName="ol">{`I trust them to lead the team and meet their objectives`}</li>
      <li parentName="ol">{`They work to make their team more effective and hold their team accountable`}</li>
      <li parentName="ol">{`They effectively balance both short and long term priorities`}</li>
      <li parentName="ol">{`They are a good listener and consider perspectives of their team when making decisions`}</li>
      <li parentName="ol">{`They are empathetic and understands their impact on others`}</li>
      <li parentName="ol">{`They are direct and honest`}</li>
      <li parentName="ol">{`They are reliable and are measured under stress`}</li>
      <li parentName="ol">{`They take responsibility for mistakes`}</li>
      <li parentName="ol">{`They work effectively with colleagues from diverse backgrounds`}</li>
      <li parentName="ol">{`They are open to constructive feedback`}</li>
      <li parentName="ol">{`They adapt to new information and make data-driven decisions`}</li>
      <li parentName="ol">{`They are good at problem solving and changing course when needed`}</li>
      <li parentName="ol">{`They inspire others to do their best work and are capable of dealing with conflict`}</li>
      <li parentName="ol">{`They lead by example and model values of the org`}</li>
      <li parentName="ol">{`They are able to convey complex ideas in conversation and in writing`}</li>
      <li parentName="ol">{`They are proactive and thoughtful in giving positive and constructive feedback`}</li>
    </ol>
    <p><strong parentName="p">{`Written response:`}</strong></p>
    <ol>
      <li parentName="ol">{`Anything to elaborate on the above?`}</li>
      <li parentName="ol">{`What are their strengths as a leader?`}</li>
      <li parentName="ol">{`What are their biggest growth areas as a leader?`}</li>
      <li parentName="ol">{`What leadership challenges do you see across the organization? (including things not specific to this leader)`}</li>
      <li parentName="ol">{`Any additional thoughts/feedback?`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      